import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-des-moines-iowa.png'
import image0 from "../../images/cities/scale-model-of-des-moines'-downtown-farmers’-market-in-des-moines-iowa.png"
import image1 from "../../images/cities/scale-model-of-des-moines-art-center-in-des-moines-iowa.png"
import image2 from "../../images/cities/scale-model-of-pappajohn-sculpture-park-in-des-moines-iowa.png"
import image3 from "../../images/cities/scale-model-of-better-homes-and-gardens-test-garden-in-des-moines-iowa.png"
import image4 from "../../images/cities/scale-model-of-terrace-hill-historic-site-in-des-moines-iowa.png"
import image5 from "../../images/cities/scale-model-of-salisbury-house-&-gardens-in-des-moines-iowa.png"
import image6 from "../../images/cities/scale-model-of-state-historical-museum-of-iowa-in-des-moines-iowa.png"
import image7 from "../../images/cities/scale-model-of-world-food-prize-hall-of-laureates-in-des-moines-iowa.png"
import image8 from "../../images/cities/scale-model-of-catch-des-moines---greater-des-moines-convention-&-visitors-bureau-in-des-moines-iowa.png"
import image9 from "../../images/cities/scale-model-of-iowa-tourism-office-in-des-moines-iowa.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Des Moines'
            state='Iowa'
            image={image}
            lat='41.5868353'
            lon='-93.6249593'
            attractions={ [{"name": "Des Moines' Downtown Farmers\u2019 Market", "vicinity": "400 Court Ave, Des Moines", "types": ["point_of_interest", "establishment"], "lat": 41.5850409, "lng": -93.62165319999997}, {"name": "Des Moines Art Center", "vicinity": "4700 Grand Ave, Des Moines", "types": ["art_gallery", "museum", "store", "point_of_interest", "establishment"], "lat": 41.5838826, "lng": -93.6815186}, {"name": "Pappajohn Sculpture Park", "vicinity": "1330 Grand Ave, Des Moines", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5851577, "lng": -93.63529440000002}, {"name": "Better Homes and Gardens Test Garden", "vicinity": "1716 Locust St, Des Moines", "types": ["park", "point_of_interest", "establishment"], "lat": 41.58489489999999, "lng": -93.63757720000001}, {"name": "Terrace Hill Historic Site", "vicinity": "2300 Grand Ave, Des Moines", "types": ["point_of_interest", "establishment"], "lat": 41.583273, "lng": -93.6492409}, {"name": "Salisbury House & Gardens", "vicinity": "4025 Tonawanda Dr, Des Moines", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5794307, "lng": -93.67170540000001}, {"name": "State Historical Museum of Iowa", "vicinity": "600 E Locust St, Des Moines", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.590626, "lng": -93.60904599999998}, {"name": "World Food Prize Hall of Laureates", "vicinity": "100 Locust St, Des Moines", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.5875745, "lng": -93.61908900000003}, {"name": "Catch Des Moines - Greater Des Moines Convention & Visitors Bureau", "vicinity": "400 Locust St #265, Des Moines", "types": ["travel_agency", "restaurant", "food", "lodging", "point_of_interest", "establishment"], "lat": 41.5868, "lng": -93.622792}, {"name": "Iowa Tourism Office", "vicinity": "200 E Grand Ave, Des Moines", "types": ["point_of_interest", "establishment"], "lat": 41.5904351, "lng": -93.61450660000003}] }
            attractionImages={ {"Des Moines' Downtown Farmers’ Market":image0,"Des Moines Art Center":image1,"Pappajohn Sculpture Park":image2,"Better Homes and Gardens Test Garden":image3,"Terrace Hill Historic Site":image4,"Salisbury House & Gardens":image5,"State Historical Museum of Iowa":image6,"World Food Prize Hall of Laureates":image7,"Catch Des Moines - Greater Des Moines Convention & Visitors Bureau":image8,"Iowa Tourism Office":image9,} }
       />);
  }
}